<template>
  <main class="mt-16 mb-8 mx-5">
    <div class="mb-8 ">
      <trac-custom-header class="mt-5">
      <template slot="leading">Select</template>
      <template slot="trailing"> Store</template>
    </trac-custom-header>
    </div>
    <trac-dropdown-exteneded
      @optionSelected="selectOption($event)"
      placement="left"
      :options="stores"
      :neededProperty="'name'"
      class="my-6"
    >
    </trac-dropdown-exteneded>
    <div class="flex flex-row justify-end mt-5">
      <trac-button
        :disabled="!selectedStore"
        @button-clicked="gotoSelectProductToAdjustScreen"
        class="uppercase"
        >Confirm</trac-button
      >
    </div>
  </main>
</template>

<script>
import { SAVE_LOCAL_DB_DATA } from "../../../browser-db-config/localStorage";

export default {
  name: "SelectStore",
  props: ["stores", "adjustmentType"],
  data() {
    return {
      selectStoreModal: false,
      selectedStore: null,
    };
  },
  async created() {},
  methods: {
    gotoSelectProductToAdjustScreen() {
      if (this.adjustmentType === 'add') {
        this.$router.push({ name: 'AddStock' })
      } else {
        this.$router.push({ name: 'RemoveStock' })
      }
    },
    selectOption(option) {
      this.selectedStore = option;
      SAVE_LOCAL_DB_DATA("stock-adjustment-store-detail", this.selectedStore);
    },
  },
};
</script>

<style scoped></style>
